export const environment = {
  production: false,
  api: {
    baseUrl: "http://localhost:8080"
  },
  security: {
    recaptchaKey: "6Ld8L8IUAAAAACA4khPeyJexFV_Boscu9x9N59Jl",
    secretKeyCripto: "N&iBich@L&oCot@toJ&ffB&bumJu@nJapon&sMuniz&nrol@doV@l&rioBonz@o"
  },
  others: {
    dateBeta: "2019-12-27T23:00:00"
    // dateBeta: "2021-10-02T23:00:00"
  }
};
