import { Injectable } from '@angular/core';
import { FantasyApiService } from '../api/fantasy-api.service';
import { AuthenticateService } from '../auth/authenticate.service';
import { map, delay, catchError, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginResponse } from '../api/contracts/login.response';
import { Observable, throwError } from 'rxjs';
import { UpdatePasswordUI } from 'src/app/models/update-password-ui.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateRepositoryService {

  constructor(private _api: FantasyApiService, private _auth: AuthenticateService) { }

  login(email: string, password: string) {
    return this._api.login({ grant_type: 'password', email: email, password: password })
      .pipe(delay(1000))
      .pipe(
        map(
          (resp) => {
            this._auth.setEmail(email)
            this._auth.setPassword(password)
            this._auth.setToken(resp.accessToken)
            return resp
          }
        )
      )
  }

  recover(email: string) {
    return this._api.recover({ email: email })
      .pipe(delay(1000))
  }

  register(email: string, password: string, confirmPassword: string) {
    console.log("register")
    return this._api.register({ AccountName: email, Email: email, Password: password, ConfirmPassword: confirmPassword, AgreeReciveNews: true })
      .pipe(
        mergeMap(
          (resp) => {
            return this.login(email, password)
          }
        )
      )
  }

  updatePassword(updatePassword: UpdatePasswordUI) {
    return this._api.updatePassword({ oldPassword: updatePassword.previousPassword, password: updatePassword.newPassword, confirmPassword: updatePassword.confirmPassword })
      .pipe(delay(1000))
  }

}
