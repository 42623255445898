import { Injectable } from '@angular/core';
import { FantasyApiService } from '../api/fantasy-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorldUI } from 'src/app/models/world-ui.model';

@Injectable({
  providedIn: 'root'
})
export class WorldRepositoryService {

  constructor(private _api: FantasyApiService) { }

  getWorlds = (): Observable<WorldUI[]> => {
    return this._api.getWorlds()
      .pipe(
        map(
          (resp) => resp.data.worlds.map((world) => {
            return { id: world.worldId, name: world.name, version: world.version, playersOnline: world.playersOnline }
          })
        )
      )
  }
}
