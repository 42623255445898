import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginRequest } from './contracts/login.request';
import { LoginResponse } from './contracts/login.response';
import { RegisterRequest } from './contracts/register.request';
import { RegisterResponse } from './contracts/register.response';
import { NewKeyRequest } from './contracts/new-key.request';
import { NewKeyResponse } from './contracts/new-key.response';
import { KeyResponse } from './contracts/key.response';
import { ValidateKeyRespnse } from './contracts/validate-key.response';
import { ValidateKeyRequest } from './contracts/validate-key.resquest';
import { WorldResponse } from './contracts/world.response';
import { CharacterResponse } from './contracts/character.response';
import { NewCharacterRequest } from './contracts/new-character.request';
import { NewCharacterResponse } from './contracts/new-character.response';
import { DonateRequest } from './contracts/donate.request';
import { DonateResponse } from './contracts/donate.response';
import { KeyConfigResponse } from './contracts/key-confing.response';
import { RecoverRequest } from './contracts/recover.request';
import { RecoverResponse } from './contracts/recover.response';
import { UpdatePasswordRequest } from './contracts/update-password.request';
import { UpdatePasswordResponse } from './contracts/update-password.response';

@Injectable({
  providedIn: 'root'
})
export class FantasyApiService {

  constructor(private http: HttpClient) { }

  login = (loginRequest: LoginRequest) => this.http.post<LoginResponse>(`${environment.api.baseUrl}/authentication/token`, loginRequest)

  recover = (recoverRequest: RecoverRequest) => this.http.post(`${environment.api.baseUrl}/account/recover_password`, recoverRequest)

  register = (registerRequest: RegisterRequest) => this.http.post<RegisterResponse>(`${environment.api.baseUrl}/account/register`, registerRequest)

  updatePassword = (updatePasswordRequest: UpdatePasswordRequest) => this.http.post(`${environment.api.baseUrl}/account/update_password`, updatePasswordRequest)

  newKey = (newTokenRequest: NewKeyRequest) => this.http.post<NewKeyRequest>(`${environment.api.baseUrl}/key`, newTokenRequest)

  getKeys = () => this.http.get<KeyResponse>(`${environment.api.baseUrl}/key`)

  validateKey = (validateKeyRequest: ValidateKeyRequest) => this.http.post<ValidateKeyRespnse>(`${environment.api.baseUrl}/key/validate`, validateKeyRequest)

  getKeyConfig = () => this.http.get<KeyConfigResponse>(`${environment.api.baseUrl}/key/config`)

  getWorlds = () => this.http.get<WorldResponse>(`${environment.api.baseUrl}/world`)

  newCharacter = (newCharacterRequest: NewCharacterRequest) => this.http.post<NewCharacterResponse>(`${environment.api.baseUrl}/account/characters`, newCharacterRequest)

  getCharacters = () => this.http.get<CharacterResponse[]>(`${environment.api.baseUrl}/account/players`)

  newDonate = (donateRequest: DonateRequest) => this.http.post<DonateResponse>(`${environment.api.baseUrl}/mercadopago/create-order`, donateRequest)

}
