import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DonateRepositoryService } from 'src/app/services/repository/donate-repository.service';
import { KeyRepositoryService } from 'src/app/services/repository/key-repository.service';
import { KeyConfigUI } from 'src/app/models/key-config-ui.model';
import { environment } from 'src/environments/environment';
declare var $: any
@Component({
  selector: 'app-fantasy-news',
  templateUrl: './fantasy-news.component.html',
  styleUrls: ['./fantasy-news.component.css']
})

export class FantasyNewsComponent implements OnInit {
  timeBeta = new Date(environment.others.dateBeta).getTime()
  difference: number
  days: number
  hours: number
  minutes: number
  seconds: number

  errors: string[]
  isLoading: boolean
  keyConfig: KeyConfigUI

  constructor(private _donateRepository: DonateRepositoryService, private _keyRepository: KeyRepositoryService) { }

  ngOnInit() {
    this.errors = []
    this.isLoading = false
    this.keyConfig = new KeyConfigUI()

    this._timer()
  }

  private _timer() {
    this.difference = this.timeBeta - Date.now()

    if (this.difference > 0) {
      this.days = Math.floor(this.difference / (1000 * 3600 * 24))
      this.hours = Math.floor(this.difference / (1000 * 3600) % 24)
      this.minutes = Math.floor(this.difference / (1000 * 60) % 60)
      this.seconds = Math.floor(this.difference / 1000 % 60)

      setTimeout(() => {
        this._timer()
      }, 1000)
    }
  }

  onDonate(productType: number) {
    this.errors = []
    this.isLoading = true

    this._donateRepository.newDonate(productType)
      .subscribe(
        (resp) => {
          window.open(resp, "_blank")
        },
        (err: any) => {
          this.errors = err.errors
          this.isLoading = false
        },
      )
  }

}
