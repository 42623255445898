import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticateService } from '../services/auth/authenticate.service';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class FantasyErrorInterceptor implements HttpInterceptor {
  constructor(private _authenticateService: AuthenticateService, private _router: Router, private _auth: AuthenticateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap(
          () => { },
          (err) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status != 401) {
                return
              }

              this._auth.logout()
              this._router.navigate(['login'])
            }
          }
        )
      )
      .pipe(
        catchError(this.handleError)
      )
  }

  private handleError(exception: any) {
    exception.errors = exception.error.errors

    if (!exception.errors) {
      exception.errors = [
        exception.message
      ]
    }

    return throwError(exception);
  }

}