import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthenticateRepositoryService } from 'src/app/services/repository/authenticate-repository.service';

@Component({
  selector: 'app-fantasy-forgot-my-password',
  templateUrl: './fantasy-forgot-my-password.component.html',
  styleUrls: ['./fantasy-forgot-my-password.component.css']
})
export class FantasyForgotMyPasswordComponent implements OnInit {
  successes: string[]
  errors: string[]
  isLoading: boolean

  email: string

  constructor(private _recaptchaV3Service: ReCaptchaV3Service, private _authenticateRepository: AuthenticateRepositoryService) { }

  ngOnInit() {
    this.successes = []
    this.errors = []
    this.isLoading = false

    this.email = ''
  }

  onRecoverPassword() {
    this.successes = []
    this.errors = []
    this.isLoading = true

    this._recaptchaV3Service.execute('recoverPassword')
      .subscribe(
        (token) => {
          this._authenticateRepository.recover(this.email)
          .subscribe(
            (resp) => {
              this.successes = ['A new password has been sent to your email. Remember to check the SPAM box.']
            },
            (err: HttpErrorResponse) => {
              this.errors = err.error.errors.map(error => error.message)
              this.isLoading = false
            },
            () => {
              this.isLoading = false
            }
          )
        },
        (err) => console.log("recoverPassword - error: " + err)
      )
  }

}
