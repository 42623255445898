import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fantasy-alert-success',
  templateUrl: './fantasy-alert-success.component.html',
  styleUrls: ['./fantasy-alert-success.component.css']
})
export class FantasyAlertSuccessComponent implements OnInit {
  @Input()
  message: string

  constructor() { }

  ngOnInit() {
  }

}
