import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticateService } from 'src/app/services/auth/authenticate.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fantasy-navbar',
  templateUrl: './fantasy-navbar.component.html',
  styleUrls: ['./fantasy-navbar.component.css']
})
export class FantasyNavbarComponent implements OnInit {

  constructor(private translate: TranslateService, private auth: AuthenticateService, private router: Router) { }

  ngOnInit() {
  }

  setLang(lang: string) {
    this.translate.setDefaultLang(lang)
  }

  getLang = () => this.translate.getDefaultLang()

  isAuthenticate = () => this.auth.isAuthenticate()

  onLogout = () => {
    this.auth.logout()
    this.router.navigate([''])
  }

}
