import { Component, OnInit } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticateService } from 'src/app/services/auth/authenticate.service';
import { AuthenticateRepositoryService } from 'src/app/services/repository/authenticate-repository.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fantasy-sign-up',
  templateUrl: './fantasy-sign-up.component.html',
  styleUrls: ['./fantasy-sign-up.component.css']
})
export class FantasySignUpComponent implements OnInit {
  errors: string[]
  isLoading: boolean

  email: string
  password: string
  confirmPassword: string
  isAcceptTermsAndRules: boolean

  constructor(private recaptchaV3Service: ReCaptchaV3Service, private translate: TranslateService, private authenticateRepository: AuthenticateRepositoryService, private auth: AuthenticateService, private router: Router) { }

  ngOnInit() {
    this.errors = []
    this.isLoading = false

    this.email = ''
    this.password = ''
    this.confirmPassword = ''
    this.isAcceptTermsAndRules = false
  }

  signUp() {
    this.errors = []
    this.isLoading = true

    this.recaptchaV3Service.execute('signUp')
      .subscribe(
        (token) => {
          this.authenticateRepository.register(this.email, this.password, this.confirmPassword)
            .subscribe(
              (resp) => this.router.navigate(['my-account']),
              (err: any) => {
                this.errors = err.errors
                this.isLoading = false
              },
              () => {
                this.isLoading = false
              }
            )
        },
        (err) => console.log("signUp - error: " + err)
      )
  }

  getLang = (): string => this.translate.getDefaultLang()

}
