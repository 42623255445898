import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { AESEncryptDecryptService } from '../utils/aesencryptdecrypt.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {
  constructor(private _cryptDecrypt: AESEncryptDecryptService) { }

  setEmail = (email: string) => { localStorage.setItem('odin', this._cryptDecrypt.encrypt(email)) }
  getEmail = () => this._cryptDecrypt.decrypt(localStorage.getItem('odin'))

  setPassword = (login: string) => { localStorage.setItem('thor', this._cryptDecrypt.encrypt(login)) }
  getPassword = () => this._cryptDecrypt.decrypt(localStorage.getItem('thor'))

  setToken = (token: string) => localStorage.setItem('token', this._cryptDecrypt.encrypt(token))
  getToken = () => this._cryptDecrypt.decrypt(localStorage.getItem('token'))

  logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('odin')
    localStorage.removeItem('thor')
  }

  isAuthenticate(): boolean {
    return (this.getToken() != null)
  }

  getUserId(): number | null {
    if (this.isAuthenticate()) {
      return jwt_decode(this.getToken()).accountId
    }

    return null
  }

  getUserEmail(): number | null {
    if (this.isAuthenticate()) {
      return jwt_decode(this.getToken()).account
    }

    return null
  }

  getUserKey(): number | null {
    if (this.isAuthenticate()) {
      return jwt_decode(this.getToken()).accountKey
    }

    return null
  }

  getUserRole(): String | null {
    if (this.isAuthenticate()) {
      return jwt_decode(this.getToken()).accountRole
    }

    return null
  }

}
