import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule, Routes } from '@angular/router';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FantasyNewsComponent } from './pages/fantasy-news/fantasy-news.component';
import { FantasyNavbarComponent } from './components/fantasy-navbar/fantasy-navbar.component';
import { FantasyLoginComponent } from './pages/fantasy-login/fantasy-login.component';
import { FantasySignUpComponent } from './pages/fantasy-sign-up/fantasy-sign-up.component';
import { FantasyForgotMyPasswordComponent } from './pages/fantasy-forgot-my-password/fantasy-forgot-my-password.component';
import { environment } from 'src/environments/environment';
import { FantasyLoadingComponent } from './components/fantasy-loading/fantasy-loading.component';
import { FantasyAlertErrorComponent } from './components/fantasy-alert-error/fantasy-alert-error.component';
import { FantasyMyAccountComponent } from './pages/fantasy-my-account/fantasy-my-account.component';
import { AuthenticateGuardService } from './helpers/authenticate-guard.service';
import { AuthenticateGuardNotSessionService } from './helpers/authenticate-guard-not-session.service';
import { FantasyHttpInterceptor } from './helpers/fantasy-http.interceptor';
import { FantasyErrorInterceptor } from './helpers/fantasy-error.interceptor';
import { FantasyAlertSuccessComponent } from './components/fantasy-alert-success/fantasy-alert-success.component';

const appRoutes: Routes = [
  { path: '', component: FantasyNewsComponent },
  { path: 'login', component: FantasyLoginComponent, canActivate: [AuthenticateGuardNotSessionService] },
  { path: 'sign-up', component: FantasySignUpComponent, canActivate: [AuthenticateGuardNotSessionService] },
  { path: 'forgot-my-password', component: FantasyForgotMyPasswordComponent, canActivate: [AuthenticateGuardNotSessionService] },
  { path: 'my-account', component: FantasyMyAccountComponent, canActivate: [AuthenticateGuardService] },
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  useBothWheelAxes: true,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    FantasyNewsComponent,
    FantasyNavbarComponent,
    FantasyLoginComponent,
    FantasySignUpComponent,
    FantasyForgotMyPasswordComponent,
    FantasyLoadingComponent,
    FantasyAlertErrorComponent,
    FantasyMyAccountComponent,
    FantasyAlertSuccessComponent,
  ],
  imports: [
    BrowserModule,
    RecaptchaV3Module,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    FormsModule,
    PerfectScrollbarModule,
    AppRoutingModule
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.security.recaptchaKey },
    { provide: HTTP_INTERCEPTORS, useClass: FantasyHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FantasyErrorInterceptor, multi: true },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
