import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticateService } from 'src/app/services/auth/authenticate.service';
import { KeyRepositoryService } from 'src/app/services/repository/key-repository.service';
import { KeyUI } from 'src/app/models/key-ui.model';
import { AuthenticateRepositoryService } from 'src/app/services/repository/authenticate-repository.service';
import { WorldUI } from 'src/app/models/world-ui.model';
import { WorldRepositoryService } from 'src/app/services/repository/world-repository.service';
import { CharacterRepositoryService } from 'src/app/services/repository/character-repository.service';
import { CharacterUI } from 'src/app/models/character-ui.model';
import { UpdatePasswordUI } from 'src/app/models/update-password-ui.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-fantasy-my-account',
  templateUrl: './fantasy-my-account.component.html',
  styleUrls: ['./fantasy-my-account.component.css']
})
export class FantasyMyAccountComponent implements OnInit {
  @ViewChild('modalCreateCharacterCloseButon', { static: false }) modalCreateCharacterCloseButon;

  successes: string[]
  errors: string[]
  isLoading: boolean

  updatePassword: UpdatePasswordUI
  typeKey: number
  hashKey: string
  characters: CharacterUI[]

  constructor(private _recaptchaV3Service: ReCaptchaV3Service, private _auth: AuthenticateService, private _keyRepository: KeyRepositoryService, private _worldRepository: WorldRepositoryService,
    private _characterRepository: CharacterRepositoryService, private _authRepository: AuthenticateRepositoryService) { }

  ngOnInit() {
    this.successes = []
    this.errors = []
    this.isLoading = false

    this.updatePassword = new UpdatePasswordUI()

    this.typeKey = 0
    this.hashKey = ""

    this.characters = []

    this._loadCharacters()
  }

  getEmail = () => this._auth.getUserEmail()

  getKeyId = () => this._auth.getUserKey()

  getRole = () => this._auth.getUserRole()

  private _loadCharacters() {
    this.errors = []
    this.isLoading = true

    this._characterRepository.getCharacters()
      .subscribe(
        (resp) => {
          this.characters = resp
        },
        (err: any) => {
          this.errors = err.errors
          this.isLoading = false
        },
        () => {
          this.isLoading = false
        }
      )
  }


  onUpdatePassword() {
    this.successes = []
    this.errors = []
    this.isLoading = true

    this._recaptchaV3Service.execute('updatePassword')
      .subscribe(
        (token) => {
          this._authRepository.updatePassword(this.updatePassword)
            .subscribe(
              (resp) => {
                this.successes = ['Password successfully updated.']
              },
              (err: HttpErrorResponse) => {
                this.errors = err.error.errors.map(error => error.message)
                this.isLoading = false
              },
              () => {
                this.isLoading = false
              }
            )
        },
        (err) => console.log("updatePassword - error: " + err)
      )
  }


}
