import { Injectable } from '@angular/core';
import { FantasyApiService } from '../api/fantasy-api.service';
import { map, delay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { KeyUI } from 'src/app/models/key-ui.model';
import { Key } from 'protractor';
import { KeyConfigUI } from 'src/app/models/key-config-ui.model';

@Injectable({
  providedIn: 'root'
})
export class KeyRepositoryService {

  constructor(private _api: FantasyApiService) { }

  newKey = (type: number) => {
    return this._api.newKey({ Type: type, Origin: 0 }).pipe(delay(1000))
  }

  getKeys = (): Observable<KeyUI[]> => {
    return this._api.getKeys()
      .pipe(
        map(
          (resp) => resp.data.keys.map((key) => {
            return { id: key.keyId, hash: key.hash, type: key.type }
          })
        )
      )
  }

  validateKey = (hash: string) => {
    return this._api.validateKey({ Hash: hash }).pipe(delay(1000))
  }

  getKeyConfig = (): Observable<KeyConfigUI> => {
    return this._api.getKeyConfig()
      .pipe(delay(1000))
      .pipe(
        map(
          (resp) => {
            return { bronze: resp.data.bronze, silver: resp.data.silver, gold: resp.data.gold }
          }
        )
      )
  }

}
