import { Component, OnInit } from '@angular/core';
import { AuthenticateRepositoryService } from 'src/app/services/repository/authenticate-repository.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/services/auth/authenticate.service';

@Component({
  selector: 'app-fantasy-login',
  templateUrl: './fantasy-login.component.html',
  styleUrls: ['./fantasy-login.component.css']
})
export class FantasyLoginComponent implements OnInit {
  errors: string[]
  isLoading: boolean

  email: string
  password: string

  constructor(private authenticateRepository: AuthenticateRepositoryService, private auth: AuthenticateService, private router: Router) { }

  ngOnInit() {
    this.errors = []
    this.isLoading = false
    this.email = ''
    this.password = ''
  }

  onLogin() {
    this.errors = []
    this.isLoading = true

    this.authenticateRepository.login(this.email, this.password)
      .subscribe(
        (resp) => this.router.navigate(['my-account']),
        (err: HttpErrorResponse) => {
          this.errors = err.error.map(error => error.message)
          this.isLoading = false
        },
        () => {
          this.isLoading = false
        }
      )
  }

}
