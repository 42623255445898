import { Injectable } from '@angular/core';
import { AuthenticateService } from '../services/auth/authenticate.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateGuardNotSessionService {

  constructor(private auth: AuthenticateService, private router: Router) { }

  canActivate(route: import("@angular/router").ActivatedRouteSnapshot, state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
    if (!this.auth.isAuthenticate()) {
      return true
    }

    this.router.navigate([''])

    return false
  }
}
