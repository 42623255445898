import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fantasy-loading',
  templateUrl: './fantasy-loading.component.html',
  styleUrls: ['./fantasy-loading.component.css']
})
export class FantasyLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
