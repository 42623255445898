import { Injectable } from '@angular/core';
import { FantasyApiService } from '../api/fantasy-api.service';
import { delay, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DonateRepositoryService {

  constructor(private _api: FantasyApiService) { }

  newDonate = (productType: number): Observable<string> => {
    return this._api.newDonate({ ProductType: productType })
      .pipe(delay(1000))
      .pipe(
        map(
          (resp) => resp.data.pagSeguroUrl
        )
      )
  }
}
