import { Injectable } from '@angular/core';
import { FantasyApiService } from '../api/fantasy-api.service';
import { CharacterUI } from 'src/app/models/character-ui.model';
import { Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CharacterRepositoryService {

  constructor(private _api: FantasyApiService) { }

  newCharacter = (name: string, sex: number, worldId: number) => {
    return this._api.newCharacter({ Name: name, Sex: sex, WorldId: worldId }).pipe(delay(1000))
  }

  getCharacters = (): Observable<CharacterUI[]> => {
    return this._api.getCharacters()
      .pipe(
        map(
          (resp) => resp.map((character) => {
            return { id: character.id, name: character.name, keyType: character.keyType, level: character.level, sex: character.level }
          })
        )
      )
  }
}
